<template>
  <div class="wrapper">
    <van-nav-bar
      title="个人资料"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />
    <van-cell-group>
      <template #title>
        <van-divider
          :style="{
            color: themeColor,
            borderColor: themeColor
          }"
        >
          个人信息
        </van-divider>
      </template>
      <van-cell title="用户名" :value="$store.state.user.nickname" is-link />
      <van-cell title="手机号" :value="$store.state.user.mobile" is-link />
    </van-cell-group>
    <van-cell-group>
      <template #title>
        <van-divider
          :style="{
            color: themeColor,
            borderColor: themeColor
          }"
        >
          微信绑定
        </van-divider>
      </template>
      <van-cell value="授权微信信息并绑定" center>
        <template #icon>
          <van-icon name="wechat" color="#04BE02" size="40" />
        </template>
        <template #extra>
          <van-button class="bind-button" round size="small">绑 定</van-button>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
import { Cell, CellGroup, NavBar, Icon, Button, Divider } from "vant";
export default {
  name: "MineIndex",
  data() {
    return {
      themeColor: "#5352ed"
    };
  },
  components: {
    VanCell: Cell,
    VanCellGroup: CellGroup,
    VanNavBar: NavBar,
    VanIcon: Icon,
    VanButton: Button,
    VanDivider: Divider
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang='scss' scoped>
$color: #001f90;
.wrapper {
  .bind-button {
    background-color: $color;
    color: #FFF;
  }
}
</style>
